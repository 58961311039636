<template>
<v-container grid-list-xs v-if="!loading">
  <v-layout row wrap v-if="promotion">
    <v-flex xs12 sm6 v-if="promotion.status != 'error'">
      <view-coupon
            :promotion="promotion"
            :coupon_id="coupon_id"
            :promotion_id="promotion_id"
            :producer_id="producer_id"
          ></view-coupon>
    </v-flex>
    <v-flex xs12 sm6 v-else>
      <v-alert type="info" outlined :value="true">
        <h4>Promotion is not available or it's ended.</h4>
      </v-alert>

    </v-flex>

  </v-layout>
</v-container>

</template>

<script>

import { mapGetters } from 'vuex'

export default {

  components: {
    ViewCoupon: () =>
      import('./ViewCoupon.vue')
  },
  data() {
    return {
      prerenderReady: null,
      promotion: null,
      coupon_id: String,
      promotion_id: String,
      producer_id: String
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),

  },
  created(){
    console.log('prerenderReady is not ready: c')
    window.prerenderReady = false;
    this.prerenderReady = false
  },
  mounted() {
    window.prerenderReady = false;
    this.prerenderReady = false
    console.log('prerenderReady is not ready: m')
    this.producer_id= this.$route.params.producer_id
    this.coupon_id= this.$route.query.c
    this.promotion_id= this.$route.query.p

    this.$store
      .dispatch('lookupPromotion', {
        coupon_id: this.coupon_id,
        promotion_id: this.promotion_id,
        producer_id: this.producer_id
      })
      .then(e => {
        this.prerenderReady = true
         window.prerenderReady = true;
         console.log('prerenderReady is ready:', e)
        this.promotion = e
      })
  },
  method() {}
}
</script>
